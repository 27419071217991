<template>
  <h2>PROJECTS</h2>
  <div class="content">
    <div class="container">
      <GalleryItem
    v-for="item in portfolioItems"
    :key="item.id"
    :imgname="item.imgname"
    :pagename="item.pagename"
    :title="item.title"
    :date="item.date"
    ></GalleryItem>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import GalleryItem from '../components/GalleryItem.vue';
export default {
  name: "Home",
  components: {
    GalleryItem
  },
  data() {
    return {
      portfolioItems: [
        {id:1,imgname:'ogpimage.png',pagename:"projects/kawasaki",title:"KAWASAKI: INFORMATION SYSTEMS INTERN",date:"2021"},
        {id:2,imgname:'nextdoor.png',pagename:"projects/knacktive",title:"KNACKTIVE: GRAPHIC DESIGNER",date:"2020"},
        {id:3,imgname:'strong1.png',pagename:"projects/strong",title:"STRONG: WORKOUT APP DESIGN",date:"2020"},
        {id:4,imgname:'csiscontest1.png',pagename:"projects/csiscontest",title:"NORTHWEST CSIS T-SHIRT CONTEST WINNER",date:"2020"},
        {id:5,imgname:'partiyorkies1.png',pagename:"projects/partiyorkies",title:"WEB DEVELOPMENT II CLIENT PROJECT",date:"2019"},
        {id:6,imgname:'iss1.png',pagename:"projects/iss",title:"THE ISS: WEB DEVELOPMENT I PROJECT",date:"2018"},
        {id:7,imgname:'sigtau1.png',pagename:"projects/sigtau",title:"SIGMA TAU GAMMA: GRAPHICS CHAIR",date:"2019 - PRESENT"},
        {id:8,imgname:'amamusements1.png',pagename:"projects/amamusements",title:"WEB DEVELOPMENT I CLIENT PROJECT",date:"2018"},
      ]
    }
  }
};
</script>